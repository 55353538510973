// require('normalize.css/normalize.css')
require('../styles/main.css')
require('./page.css')

document.addEventListener('DOMContentLoaded', () => {
  console.log('DOMContentLoaded', 'page-index');

  const nav = document.getElementById('nav-home');
  nav.classList.add('current_page_item');

})
